import {createContext, useState, useContext, useEffect} from 'react';

const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState(() => {
        const pathname = window.location.pathname;
        if (pathname.includes('/tr')) {
            return 'tr';
        } else if (pathname.includes('/ru')) {
            return 'ru';
        } else if (pathname.includes('/pt')) {
            return 'pt';
        } else if (pathname.includes('/ar')) {
            return 'ar';
        } else if (pathname.includes('/fr')) {
            return 'fr';
        } else {
            return 'en';
        }
    });

    const changeLang = (value) => {
        const currentPath = window.location.pathname;

        let newPath;
        if (value === 'en') {
            newPath = currentPath.replace(/^\/(tr|ru|pt|ar|fr|en)(\/|$)/, '/');
        } else {
            newPath = currentPath.match(/^\/(tr|ru|pt|ar|fr|en)(\/|$)/)
                ? currentPath.replace(/^\/(tr|ru|pt|ar|fr|en)(\/|$)/, `/${value}/`)
                : `/${value}${currentPath}`;
        }

        window.location.replace(newPath);
    };

    const [messages, setMessages] = useState([])

    useEffect(() => {
        const getMessages = async () => {
            try {
                const response = await axios.post('/api/messages', { locale });
                const organizedMessages = response.data.messages.reduce((acc, { page, key, value }) => {
                    if (!acc[page]) acc[page] = {};
                    acc[page][key] = value;
                    return acc;
                }, {});
                setMessages(organizedMessages);
            } catch (error) {
                console.log(error);
            }
        };
        getMessages();
    }, [locale]);

    return (
        <LocaleContext.Provider value={{ locale, setLocale, messages, changeLang }}>
            {children}
        </LocaleContext.Provider>
    );
};

export const useLocale = () => {
    return useContext(LocaleContext);
};
